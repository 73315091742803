<template>
  <div>
    <a-row class="title">提现记录</a-row>
    <search-agency-withdrawals @submit="submitSearch" />
    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
          v-if="isShowNewMenu()"
        >记录提现</a-button>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    />

    <!-- 记录提现模态框 -->
    <new-agency-withdrawal
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchAgencyWithdrawals from '@/views/withdrawals/top_agent_view_agency/Search'
import { hasPermission } from '@/utils/permission'
import NewAgencyWithdrawal from '@/views/withdrawals/top_agent_view_agency/New'
import { findAgentWithdrawals, exportAgentWithdrawals } from '@/api/withdrawal'
import { getOriginalUserUid } from '@/utils/token'
import { exportExcel } from '@/api/excel'

export default {
  name: 'TopAgentViewAgencyWithdrawalList',
  components: {
    NewAgencyWithdrawal,
    SearchAgencyWithdrawals,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false,
      pagination: {
        total_count: 0
      },
      query: {
      }
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },
    agentType() {
      return parseInt(this.$store.getters.userAgentType)
    },
    columns() {
      return [
        {
          title: '事件批次',
          width: 100,
          dataIndex: 'no'
        },
        {
          title: '提现日期',
          width: 120,
          dataIndex: 'paid_at'
        },
        {
          title: '提现类型',
          width: 100,
          dataIndex: 'withdrawal_type'
        },
        {
          title: '金额(元)',
          width: 100,
          dataIndex: 'amount'
        },
        {
          title: '支付方式',
          width: 100,
          dataIndex: 'pay_way'
        },
        {
          title: '记录人',
          width: 100,
          dataIndex: 'operator_name'
        },
        {
          title: '备注',
          ellipsis: true,
          width: 200,
          dataIndex: 'remark'
        },
        {
          title: '记录时间',
          width: 180,
          dataIndex: 'created_at'
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    isShowNewMenu() {
      // 有权限 && 不是超级登录
      return this.isShowMenu('customer/withdrawals/agency/by_top_agent') && !getOriginalUserUid() && this.agentType === 1
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentWithdrawals(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      exportAgentWithdrawals(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 20px;
}
</style>
