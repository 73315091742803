<template>
  <div>
    <a-form
      class="iotplt-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="5">
          <a-form-item label="提现类型">
            <a-select
              v-decorator="['withdrawal_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in withdrawalTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="5">
          <a-form-item label="提现方式">
            <a-select
              v-decorator="['pay_way']"
              @focus="fetchPayWayOptions"
              :loading="loadingPayWayOptions"
              allow-clear
            >
              <a-select-option
                v-for="option in payWayOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item label="提现日期">
            <a-range-picker
              style="width: 100%;"
              v-decorator="['paid_at']"
            />
          </a-form-item>
        </a-col>

        <a-col :span="5">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'
import { findWithdrawalPayWayOptions, findWithdrawalTypeOptions } from '@/api/withdrawal'

export default {
  name: 'SearchAgentWithdrawals',
  data() {
    return {
      withdrawalTypeOptions: findWithdrawalTypeOptions(),
      payWayOptions: [],
      loadingPayWayOptions: false,
      form: this.$form.createForm(this, { name: 'withdrawals_search' })
    }
  },
  methods: {
    fetchPayWayOptions() {
      if (this.payWayOptions.length === 0) {
        this.loadingPayWayOptions = true
        findWithdrawalPayWayOptions().then(res => {
          if (res.code === 0) {
            this.payWayOptions = res.data
          }
          this.loadingPayWayOptions = false
        })
      }
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        paid_at_begin: formatRangeBeginDate(fieldsValue.paid_at),
        paid_at_end: formatRangeEndDate(fieldsValue.paid_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
